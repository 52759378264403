<template>
  <div v-if="loading" class="loader">
    <Loader></Loader>
  </div>
  <div v-else-if="contentPage" class="d-flex flex-column align-items-center w-100 mb-5" id="lobby">
    <div class="d-flex flex-column align-items-center w-100">

      <div class="logo-container small mt-3 mb-4">
        <img :src="contentPage.instance.header.content.values.image.value.public_path" alt="" class="los-50-logo">
      </div>
      <p v-if="!favoriteParicipant && me" class="main-p bigger font-weight-bold mb-0"
         v-html="replaceVariables(contentPage.instance.header.content.values.title.value)"></p>

      <p v-else-if="favoriteParicipant && ('eliminated' in favoriteParicipant && favoriteParicipant.eliminated) && me && eventStatus == 'active'"
         class="main-p bigger font-weight-bold mb-0"
         v-html="replaceVariables(contentPage.instance.header_eliminated.content.values.title.value)"></p>

      <p v-else-if="favoriteParicipant && ('eliminated' in favoriteParicipant && favoriteParicipant.eliminated) && me"
         class="main-p bigger font-weight-bold mb-0"
         v-html="replaceVariables(contentPage.instance['registration-end-participants-header-eliminated'].content.values.title.value)"></p>

      <p v-else-if="me" class="main-p bigger font-weight-bold mb-0"
         v-html="replaceVariables(contentPage.instance.header_fav.content.values.title.value)"></p>

<!--      <span v-if="eventStatus == 'registration_end'">-->
<!--          <p class="main-p bigger font-weight-bold mb-0"-->
<!--             v-html="replaceVariables(contentPage.instance['thanks-message'].content.values.title.value)"></p>-->
<!--        </span>-->

      <div v-if="showPlayers" >
        <div v-if="!favoriteParicipant">
          <!-- whent the contact does not have a favorite selected  -->
          <p v-if="eventStatus == 'active'" class="main-p my-0 ln-1" style="font-size: 12px;"
             v-html="replaceVariables(contentPage.instance.no_fav_selected_header.content.values.description.value)"></p>
          <p v-else-if="eventStatus == 'registration_end'" class="main-p smaller mb-1" style="font-size: 12px;"
             v-html="replaceVariables(contentPage.instance.registration_end_no_selected_fav.content.values.title.value)"></p>
          <p v-else-if="eventStatus == 'finished'" class="main-p smaller mb-1" style="font-size: 12px;"
             v-html="replaceVariables(contentPage.instance.finished_fav_header.content.values.title.value)"></p>

        </div>
        <div v-else-if="favoriteParicipant && ('eliminated' in favoriteParicipant && favoriteParicipant.eliminated)">
          <!-- when the selected favorite is eliminated -->
          <p v-if="eventStatus == 'active'" class="main-p my-0 ln-1" style="font-size: 12px;"
             v-html="replaceVariables(contentPage.instance.eliminated_fav_header.content.values.title.value)"></p>
          <p v-else-if="eventStatus == 'registration_end'" class="main-p smaller mb-1" style="font-size: 12px;"
             v-html="replaceVariables(contentPage.instance.registration_end_no_selected_fav.content.values.title.value)"></p>
          <p v-else-if="eventStatus == 'finished'" class="main-p smaller mb-1" style="font-size: 12px;"
             v-html="replaceVariables(contentPage.instance.finished_fav_header.content.values.title.value)"></p>

        </div>
        <div v-else>
          <!-- when the contact selected a favorite -->
          <p v-if="eventStatus == 'active'" class="main-p smaller mb-1" style="font-size: 12px;"
             v-html="replaceVariables(contentPage.instance.change_fav_header.content.values.title.value)"></p>
          <p v-else-if="eventStatus == 'registration_end'" class="main-p smaller mb-1" style="font-size: 12px;"
             v-html="replaceVariables(contentPage.instance.registration_end_selected_fav.content.values.title.value)"></p>
          <p v-else-if="eventStatus == 'finished'" class="main-p smaller mb-1" style="font-size: 12px;"
             v-html="replaceVariables(contentPage.instance.finished_fav_header.content.values.title.value)"></p>
        </div>
      </div>
      <div v-else class="d-flex flex-column">
        <p class="main-p my-0 ln-1"
           v-html="replaceVariables(contentPage.instance['no-revealed-players'].content.values.description.value)"></p>
        <iframe loading="lazy"
                class="mb-3"
                src="https://player.vimeo.com/video/1007832059?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                scrolling="no"
                frameborder="0" allowfullscreen
                style="min-height: 500px"
                ref="participantIframe">
        </iframe>
        <button class="main-btn" @click="logOut">
          Cerrar sesión
        </button>
      </div>

      <div v-if="showPlayers" >
        <p v-if="eventStatus === 'active'" class="main-p bigger font-weight-bold mb-1"
           v-html="replaceVariables(contentPage.instance.header.content.values.description.value)"></p>
        <div class="panel-grid mt-3 mb-5">
          <div class="panel-grid__item" v-for="participant in activeParticipants" :key="participant.story_id">

            <RouterLink :to="`/participante/${participant.slug}`" class="participant__container" tag="div"
                        :class="{ 'selected' : isParticipantSelected(participant.story_id) }">
              <img v-if="isParticipantSelected(participant.story_id)" src="https://endemolcxfprod-16905.kxcdn.com/public-assets/white-border" alt=""
                   class="participant__border"
                   :class="{ 'selected' : isParticipantSelected(participant.story_id) }">
              <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-love-pink"
                   v-if="isParticipantSelected(participant.story_id)"
                   class="participant__heart" alt=""/>
              <img v-if="participant?.picture"
                   :src="participant.picture.public_path"
                   class="img-fluid participant__img"
                   alt="">
            </RouterLink>
          </div>
        </div>
      </div>


    </div>
  </div>
  <div v-else class="loader">
    <Loader></Loader>
  </div>

</template>

<script>
import axios from 'axios';
import LobbyTabs from './LobbyTabs.vue'
import Loader from './Loader.vue';

export default {
  data() {
    return {
      contentPage: null,
      participants: [],
      favoriteParticipantId: null,
      loading: false,
      me: Object,
      showPlayers: true,
    }
  },
  props: {
    // me: Object,
    eventStatus: String
  },
  components: {LobbyTabs, Loader},
  created() {
    this.getMe()
  },
  mounted() {
    this.getSupportData();
  },
  computed: {
    activeParticipants() {
      let activeParticipants = this.participants.filter(participant => {
        if (!('eliminated' in participant && participant.eliminated)) {
          return participant;
        }
      });
      return activeParticipants
    },
    eliminatedParticipants() {
      return this.participants.filter(participant => {
        if (('eliminated' in participant && participant.eliminated)) {
          return participant;
        }
      });
    },
    favoriteParicipant() {
      // return null
      return this.participants.find(participant => participant.story_id == this.favoriteParticipantId) || null;
    },
  },
  methods: {
    logOut() {
      axios.get('api/v1/logout').then(response => {
        this.$emit('clearSessionVars');
        this.$router.push('/')
      })
      localStorage.clear();
    },
    getMe(){
      var token = localStorage.getItem('mints_contact_id');

      if (token != null) {
        let localStorageKey = 'followers.contact.' + token
        this.me = JSON.parse(window.localStorage.getItem(localStorageKey)) || null;
      }
    },
    goTo(url) {
      this.loading = true;
      window.location = url;
    },
    getSupportData() {
      if (this.me && this.me.id) {
        axios.get('api/v1/get-selected-favorite').then(response => {
          if (response.data) {
            this.favoriteParticipantId = response.data.story_id;
          }
        }).catch(error => {
          if (error.response.status == 440) {
            this.$swal.fire({
              title: 'La sesión ha expirado',
              confirmButtonText: 'Regresar al inicio',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.clear();
                window.location = '/';
              }
            })
          }
        });
      }
      axios.get('api/v1/get-content-page/lobby').then(response => {
        this.contentPage = response.data.data
        let unfiltered_participants = response.data.data.stories.participants.content
        this.participants = unfiltered_participants.filter(participant => {
          if('participante_2024' in participant && participant.participante_2024){
            return participant
          }
        })
      }).catch((error) => {
        console.log(error);
      })
    },
    isParticipantSelected(participantId) {
      if (participantId == this.favoriteParticipantId) {
        return true;
      }
      return false;
    },
    replaceVariables(text) {
      text = text.replace(/\{\{open-span\}\}/g, '<span class="font-weight-bold">');
      text = text.replace(/\{\{close-span\}\}/g, '</span>');
      text = text.replace(/\{\{br\}\}/g, '<br>');

      let givenName = this.me?.given_name || '';
      givenName = this.capitalize(givenName);
      text = text.replace(/\{\{name\}\}/g, givenName);
      return text;
    },
    capitalize(text) {
      text = text.toLowerCase();
      return text.split(' ').map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }
  }
};
</script>

<style lang="scss">

.panel-grid {
  // --grid-clamp: clamp(60px, 18vw, 150px);
  max-width: 100%;
  margin: auto;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(var(--grid-clamp), 1fr));
  // grid-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: clamp(12px, 3vw, 15px);
  justify-content: center;
}

.panel-grid__item {
  aspect-ratio: 10 / 8.4;
  cursor: pointer;
  flex: 1 1 1;
  width: clamp(60px, 18vw, 150px);
  min-width: 60px;
  max-width: 150px;
}

.participant__container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10%;
  overflow: hidden;

  .participant__border {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;

    &.selected {
      filter: invert(26%) sepia(57%) saturate(10190%) hue-rotate(292deg) brightness(105%) contrast(116%);
    }
  }

  .participant__heart {
    position: absolute;
    right: 8%;
    top: 8%;
    z-index: 3;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    object-fit: contain;
  }

  .participant__img {
    position: absolute;
    width: 96%;
    height: 96%;
    left: 2%;
    top: 2%;
    z-index: 1;
    //background-color: #835CA1;
  }
}

</style>