<template>
    <div>
      <div class="fake-height" style="height:75px"></div>
        <div v-if="isReturn" class="lobby-tabs" style="padding-left: 20px!important; justify-content: start !important;">
          <button class="btn btn-primary"
                  style="
                  background-color: var(--main-color);
                  border-radius: 10px; border: none;
                  outline: none;
                  display: flex;
                  align-items: center;
                  padding: 10px;
                  font-size: 12px;
                  font-weight: bold;" @click="goBack" >
            <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-arrow" alt="" class="back-icon" style="margin-right: 5px;">
            Regresar
          </button>
        </div>
        <div v-else class="lobby-tabs">
          <RouterLink to="/lobby" class="d-flex flex-column align-items-center" :class="{'selected': selectedTab === 'participants'}">
            <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-participantes-pink" alt="" style="width: 35px;margin-top: 3px;">
            <div style="font-size: 12px;">Jugadores</div>
          </RouterLink>
          <RouterLink v-if="eventStatus === 'active' && !isPreRegister" to="/interacciones" class="d-flex flex-column align-items-center" :class="{'selected': selectedTab === 'interactions'}">
            <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-interacciones-pink" alt="" style="width: 30px;">
            <div style="font-size: 12px;">Interacciones</div>
          </RouterLink>
          <RouterLink v-if="(eventStatus === 'registration_end' || eventStatus === 'active') && !isPreRegister" to="/perfil" class="d-flex flex-column align-items-center" :class="{'selected': selectedTab === 'profile'}">
            <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-perfil-pink" alt="" style="width: 30px;height: 27px;margin-top: 3px;">
            <div style="font-size: 12px;">Mi perfil</div>
          </RouterLink>
        </div>
    </div>
    
</template>

<script>

export default {
  data() {
    return {
    }
  },
  props: {
      selectedTab: {
        type: String
      },
      hasSession: Boolean,
      isReturn: Boolean,
      eventStatus: String,
      isPreRegister: Boolean,
  },
  mounted() {

  },
  methods:{
    goBack() {
      this.$router.go(-1);
    }
  },
};
</script>